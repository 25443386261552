<template>
    <div class="origen">
       
      <div class="mensaje" v-if="data[0].estado < 3" >
      
          <span>
 Su pedido fue recibido correctamente, la fecha de entrega estimadad es entre 3 y 10 días hábiles. 
        <br>
        <br>
       Una copia de su pedido ha sido enviado a su mail, en caso de no recibir la misma, por favor revise su carpeta de Correo no Deseado o comuníquese con nosotros.
          </span>
        <small style="display:block; text-align:right;  padding-top:10px">Pedido sujeto a stock disponible, algunos productos podrían no encontrarse actualmente en stock. Para más información comunicarse a nuestro centro de atención</small>
      </div>
       <div class="mensaje" v-if="data[0].estado == 3" >
        
            <span>
                <strong>Pedido en preparación</strong>
                <br>
                Estamos preparando tu pedido,pronto recibirás un correo con los detalles de facturación y envío.
            </span>

       </div>
        <div class="mensaje" v-if="data[0].estado == 4" >
        
            <span>
                <strong>Pedido facturado</strong>
                <br>
                Tu pedido ya fue facturado, pronto recibirás un correo con los detalles del envío.
            </span>
                    <small style="display:block; text-align:right;  padding-top:10px">
                        Ante cualquier duda comunicarse a nuestro centro de atención</small>


       </div>
        <div class="mensaje" v-if="data[0].estado == 5" >
        
            <span>
                <strong>Pedido enviado</strong>
                <br>
                Tu pedido fue enviado a la dirección indicada. Muchas gracias por tu compra
            </span>
                    <small style="display:block; text-align:right;  padding-top:10px">
                        Ante cualquier duda comunicarse a nuestro centro de atención</small>


       </div>
        <div class="mensaje" v-if="data[0].estado == 7" >
        
            <span>
                <strong>Pedido entregado</strong>
                <br>
                Tu pedido fue entregado a la dirección indicada. Muchas gracias por tu compra
            </span>
                    <small style="display:block; text-align:right;  padding-top:10px">
                        Ante cualquier duda comunicarse a nuestro centro de atención</small>


       </div>
        <div class="mensaje" v-if="[98,99].includes(data[0].estado)" >
        
            <span>
                <strong>Pedido cancelado</strong>
                <br>
               Tu pedido fue cancelado y no será procesado, si se trata de un error comunicarse a nuestro centro de atención al cliente.
            </span>
                    <small style="display:block; text-align:right;  padding-top:10px">
                        Ante cualquier duda comunicarse a nuestro centro de atención</small>


       </div>
    </div>
</template>

<script>
    export default {
        props:['data']
    }
</script>

<style lang="scss" scoped>
.origen{
    width: 100%;
    border-top: 1px solid #ddd;
}
.mensaje > i, .mensaje > span{
    display: inline-block;
}
.mensaje small{
    // font-weight: bold;
    color: rgb(192, 47, 47);
}
.mensaje > i{
    font-size: 2em;
    margin-right: 10px;
}
.mensaje{
    font-size: 16px;
    padding: 20px;
    padding-bottom: 10px;
    text-align: left;
    // background: #000;
    // border-radius: 0px 0px 8px 7px;
    color: #333;
    // width: 80%;
    margin: 0 auto;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #d9473e;
    border-left: 1px solid #d9473e;
    // box-shadow: 2px 1px 3px #ddd;
}
@media screen and (max-width:768px) {
 .mensaje{
    font-size: 14px;
    padding: 10px;
    padding-bottom: 10px;
    text-align: left;
    // background: #000;
    // border-radius: 0px 0px 8px 7px;
    color: #333;
    // width: 80%;
    margin: 0 auto;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #d9473e;
    border-left: 1px solid #d9473e;
    // box-shadow: 2px 1px 3px #ddd;
    margin-left: -15px;
}   
}
</style>