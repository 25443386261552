var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"origen"},[(_vm.data[0].estado < 3)?_c('div',{staticClass:"mensaje"},[_vm._m(0),_c('small',{staticStyle:{"display":"block","text-align":"right","padding-top":"10px"}},[_vm._v("Pedido sujeto a stock disponible, algunos productos podrían no encontrarse actualmente en stock. Para más información comunicarse a nuestro centro de atención")])]):_vm._e(),(_vm.data[0].estado == 3)?_c('div',{staticClass:"mensaje"},[_vm._m(1)]):_vm._e(),(_vm.data[0].estado == 4)?_c('div',{staticClass:"mensaje"},[_vm._m(2),_c('small',{staticStyle:{"display":"block","text-align":"right","padding-top":"10px"}},[_vm._v(" Ante cualquier duda comunicarse a nuestro centro de atención")])]):_vm._e(),(_vm.data[0].estado == 5)?_c('div',{staticClass:"mensaje"},[_vm._m(3),_c('small',{staticStyle:{"display":"block","text-align":"right","padding-top":"10px"}},[_vm._v(" Ante cualquier duda comunicarse a nuestro centro de atención")])]):_vm._e(),(_vm.data[0].estado == 7)?_c('div',{staticClass:"mensaje"},[_vm._m(4),_c('small',{staticStyle:{"display":"block","text-align":"right","padding-top":"10px"}},[_vm._v(" Ante cualquier duda comunicarse a nuestro centro de atención")])]):_vm._e(),([98,99].includes(_vm.data[0].estado))?_c('div',{staticClass:"mensaje"},[_vm._m(5),_c('small',{staticStyle:{"display":"block","text-align":"right","padding-top":"10px"}},[_vm._v(" Ante cualquier duda comunicarse a nuestro centro de atención")])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v(" Su pedido fue recibido correctamente, la fecha de entrega estimadad es entre 3 y 10 días hábiles. "),_c('br'),_c('br'),_vm._v(" Una copia de su pedido ha sido enviado a su mail, en caso de no recibir la misma, por favor revise su carpeta de Correo no Deseado o comuníquese con nosotros. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('strong',[_vm._v("Pedido en preparación")]),_c('br'),_vm._v(" Estamos preparando tu pedido,pronto recibirás un correo con los detalles de facturación y envío. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('strong',[_vm._v("Pedido facturado")]),_c('br'),_vm._v(" Tu pedido ya fue facturado, pronto recibirás un correo con los detalles del envío. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('strong',[_vm._v("Pedido enviado")]),_c('br'),_vm._v(" Tu pedido fue enviado a la dirección indicada. Muchas gracias por tu compra ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('strong',[_vm._v("Pedido entregado")]),_c('br'),_vm._v(" Tu pedido fue entregado a la dirección indicada. Muchas gracias por tu compra ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('strong',[_vm._v("Pedido cancelado")]),_c('br'),_vm._v(" Tu pedido fue cancelado y no será procesado, si se trata de un error comunicarse a nuestro centro de atención al cliente. ")])
}]

export { render, staticRenderFns }